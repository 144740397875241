
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonSpinner,
  IonText,
  /*IonCard,
  IonCardTitle,
  IonCardHeader,
  IonCardContent,*/
  IonLabel,
  IonList,
  IonItem,
  IonIcon,
  IonFab,
  IonFabButton,
} from "@ionic/vue";
import { refresh } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import apiInterventi from "../services/interventi";
import { openToast } from "../services/toast";

import moment from "moment";

export default {
  name: "Tab2",
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonPage,
    IonSpinner,
    IonText,
    /*IonCard,
    IonCardTitle,
    IonCardHeader,
    IonCardContent,*/
    IonLabel,
    IonList,
    IonItem,
    IonIcon,
    IonFab,
    IonFabButton,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const interventi = ref([]);
    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id;

    /**
     *
     * Print name and last name or customer company based on condition
     *
     */
    // const riferimentoCliente = computed(() => {
    //   return (intervento) => {
    //     //console.log(intervento);
    //     if (
    //       (intervento.customers_name != null &&
    //         intervento.customers_last_name != null) ||
    //       (intervento.customers_name != "" &&
    //         intervento.customers_last_name != "")
    //     ) {
    //       return `${intervento.customers_name} ${intervento.customers_last_name}`;
    //     } else {
    //       return `${intervento.customers_company}`;
    //     }
    //   };
    // });

    function riferimentoCliente(intervento) {
      if (intervento.customers_company) {
        return `${intervento.customers_company}`;
      } else {
        return `${intervento.customers_name} ${intervento.customers_last_name}`;
      }
    }

    /**
     *
     * Return '-' if a customer field is null
     *
     */
    function checkField(field) {
      if (field == null) {
        return " ";
      } else {
        return " - " + field;
      }
    }

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (moment.isDate(new Date(date))) {
        return moment(date).format("DD/MM/YYYY");
      } else {
        return "-";
      }
    }

    /**
     *
     * Passing id to retrive detail in InterventoDetail page
     *
     */
    function openDetail(id: string) {
      router.push(`tab2/${id}`);
    }

    function getInterventi() {
      //console.log("getInterventi");
      loading.value = true;
      apiInterventi
        .getInterventi(userID)
        .then((response) => {
          interventi.value = response;
        })
        .catch((error) => {
          //error.value = true;
          openToast("Errore durante la richiesta degli interventi", "danger");
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
        });
    }

    onMounted(() => {
      //GET INTERVENTI DATA
      getInterventi();
    });

    return {
      loading,
      interventi,
      riferimentoCliente,
      dateFormat,
      refresh,
      openDetail,
      getInterventi,
      checkField,
    };
  },
};
